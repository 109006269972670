@use "libs/shared/styles/app-theme" as *;
@use "libs/shared/styles/style-guide-typography" as *;
@use "libs/shared/styles/mixins" as *;
@use "libs/shared/styles/style-guide-colors" as *;

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

:root {
    --ion-background-color: #{$color-gray-50};
    --ion-font-family: #{$typography-font-family};
    --ion-text-color: #{$color-gray-700};
    --pulse-color: #{rgba($color-gray-500, 0.8)};
    --color-warning: #f56a00;
}

.ion-page {
    overflow-y: auto;
    background-color: $color-gray-50;
}

ion-content {
    --overflow: hidden;
}

ion-card {
    @include default-box-shadow;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 20px 16px;
    border-radius: 8px;
    background: $color-white;
}

ion-app,
drone-tower-mobile-root,
drone-tower-mobile-lib-map {
    display: block;
    height: 100%;
    width: 100%;
}

.toast-with-actions {
    a {
        @include heading-6;
        display: block;
        margin-top: 12px;
        padding: 10px 0;
    }
}

.leaflet-attribution-flag {
    display: none !important;
}

.pulse {
    animation: pulse-animation 2s infinite;
    border-radius: 50%;

    // NOTE: pulse color matches icon color
    &-created {
        --pulse-color: #{rgba($color-white, 0.8)};
    }

    &-active {
        --pulse-color: #{rgba($color-primary-400, 0.8)};
    }

    &-accepted {
        --pulse-color: #{rgba($color-status-success, 0.8)};
    }

    &-atc_modified {
        --pulse-color: #{rgba($color-secondary-500, 0.8)};
    }

    &-land_now {
        --pulse-color: #{rgba(#7800b0, 0.8)};
    }

    &-atc_waiting {
        --pulse-color: #{rgba($color-gray-100, 0.8)};
    }

    &-overdue {
        --pulse-color: #{rgba(#965b2d, 0.8)};
    }

    &-lost_control {
        --pulse-color: #{rgba($color-status-error, 0.8)};
    }

    &-rejected {
        --pulse-color: #{rgba($color-status-error-background-hover, 0.8)};
    }
}

@keyframes pulse-animation {
    0% {
        box-shadow: 0 0 0 0px var(--pulse-color);
    }
    100% {
        box-shadow: 0 0 0 20px transparent;
    }
}

mat-slide-toggle {
    .mdc-switch {
        --mdc-switch-track-height: 22px;
        --mdc-switch-track-width: 44px;
        --mdc-switch-track-shape: 20px;
    }

    .mdc-switch__handle {
        --mdc-switch-handle-height: 18px;
        --mdc-switch-handle-width: 18px;
        --mdc-switch-selected-handle-color: #{$color-white};
        --mdc-switch-selected-focus-handle-color: #{$color-white};
        --mdc-switch-selected-hover-handle-color: #{$color-white};
        --mdc-switch-selected-pressed-handle-color: #{$color-white};

        --mdc-switch-unselected-handle-color: #{$color-white};
        --mdc-switch-unselected-focus-handle-color: #{$color-white};
        --mdc-switch-unselected-hover-handle-color: #{$color-white};
        --mdc-switch-unselected-pressed-handle-color: #{$color-white};
    }

    .mdc-switch__track {
        --mdc-switch-selected-focus-track-color: #{$color-secondary-700};
        --mdc-switch-selected-hover-track-color: #{$color-secondary-700};
        --mdc-switch-selected-pressed-track-color: #{$color-secondary-700};
        --mdc-switch-selected-track-color: #{$color-secondary-700};

        --mdc-switch-unselected-focus-track-color: #{$color-gray-100};
        --mdc-switch-unselected-hover-track-color: #{$color-gray-100};
        --mdc-switch-unselected-pressed-track-color: #{$color-gray-100};
        --mdc-switch-unselected-track-color: #{$color-gray-100};
    }

    .mdc-switch__handle-track {
        transform: translateX(2px);
    }
    .mdc-switch__icon {
        opacity: 0;
    }
}
