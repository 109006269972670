@use "libs/shared/styles/style-guide-colors" as *;
@use "sass:string";
@use "sass:color";

@function hexToRgbValues($hex) {
    @return string.slice(#{rgba($hex, 0.1)}, 6, -7);
}

@function shade($color) {
    @return color.scale($color, $lightness: -10%);
}

@function tint($color) {
    @return color.scale($color, $lightness: 10%);
}

:root {
    /** primary **/
    --ion-color-primary: #{$color-primary-400};
    --ion-color-primary-rgb: #{hexToRgbValues($color-primary-400)};
    --ion-color-primary-contrast: #{$color-dark-contrast};
    --ion-color-primary-contrast-rgb: #{hexToRgbValues($color-dark-contrast)};
    --ion-color-primary-shade: #{shade($color-primary-400)};
    --ion-color-primary-tint: #{tint($color-primary-400)};

    /** secondary **/
    --ion-color-secondary: #{$color-secondary-700};
    --ion-color-secondary-rgb: #{hexToRgbValues($color-secondary-700)};
    --ion-color-secondary-contrast: #{$color-light-contrast};
    --ion-color-secondary-contrast-rgb: #{hexToRgbValues($color-light-contrast)};
    --ion-color-secondary-shade: #{shade($color-secondary-700)};
    --ion-color-secondary-tint: #{tint($color-secondary-700)};

    /** tertiary **/
    --ion-color-tertiary: #{$color-gray-400};
    --ion-color-tertiary-rgb: #{hexToRgbValues($color-gray-400)};
    --ion-color-tertiary-contrast: #{$color-light-contrast};
    --ion-color-tertiary-contrast-rgb: #{hexToRgbValues($color-light-contrast)};
    --ion-color-tertiary-shade: #{shade($color-gray-400)};
    --ion-color-tertiary-tint: #{tint($color-gray-400)};

    /** success **/
    --ion-color-success: #{$color-status-success};
    --ion-color-success-rgb: #{hexToRgbValues($color-status-success)};
    --ion-color-success-contrast: #{$color-light-contrast};
    --ion-color-success-contrast-rgb: #{hexToRgbValues($color-light-contrast)};
    --ion-color-success-shade: #{shade($color-status-success)};
    --ion-color-success-tint: #{tint($color-status-success)};

    /** warning **/
    --ion-color-warning: #{$color-status-warning};
    --ion-color-warning-rgb: #{hexToRgbValues($color-status-warning)};
    --ion-color-warning-contrast: #{$color-dark-contrast};
    --ion-color-warning-contrast-rgb: #{hexToRgbValues($color-dark-contrast)};
    --ion-color-warning-shade: #{shade($color-status-warning)};
    --ion-color-warning-tint: #{tint($color-status-warning)};

    /** danger **/
    --ion-color-danger: #{$color-error-400};
    --ion-color-danger-rgb: #{hexToRgbValues($color-error-400)};
    --ion-color-danger-contrast: #{$color-light-contrast};
    --ion-color-danger-contrast-rgb: #{hexToRgbValues($color-light-contrast)};
    --ion-color-danger-shade: #{shade($color-error-400)};
    --ion-color-danger-tint: #{tint($color-error-400)};

    /** dark **/
    --ion-color-dark: #{$color-gray-900};
    --ion-color-dark-rgb: #{hexToRgbValues($color-gray-900)};
    --ion-color-dark-contrast: #{$color-light-contrast};
    --ion-color-dark-contrast-rgb: #{hexToRgbValues($color-light-contrast)};
    --ion-color-dark-shade: #{shade($color-gray-900)};
    --ion-color-dark-tint: #{tint($color-gray-900)};

    /** medium **/
    --ion-color-medium: #{$color-gray-200};
    --ion-color-medium-rgb: #{hexToRgbValues($color-gray-200)};
    --ion-color-medium-contrast: #{$color-dark-contrast};
    --ion-color-medium-contrast-rgb: #{hexToRgbValues($color-dark-contrast)};
    --ion-color-medium-shade: #{shade($color-gray-200)};
    --ion-color-medium-tint: #{tint($color-gray-200)};

    /** light **/
    --ion-color-light: #{$color-gray-50};
    --ion-color-light-rgb: #{hexToRgbValues($color-gray-50)};
    --ion-color-light-contrast: #{$color-dark-contrast};
    --ion-color-light-contrast-rgb: #{hexToRgbValues($color-dark-contrast)};
    --ion-color-light-shade: #{shade($color-gray-50)};
    --ion-color-light-tint: #{tint($color-gray-50)};
}
